.Header{
   display: flex;
   justify-content: space-between;
}
.logo{
    width: 10rem;
    height: 3rem;
}
.Header-menu{
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white
}
.Header-menu>li:hover{
    cursor: pointer;
    color: orange;
}


@media screen and (max-width: 768px)
{
    .Header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 99;
    }
    .Header-menu{
        flex-direction: column;
        background-color: #3c3F45;
        padding: 1rem;
    }
}